<template>
  <div class="content">
    <el-form status-icon label-width="210px" :model="firstAllDateInfo" :rules="firstAllRules" ref="firstFormRef">
      <Title :title="title[0]"></Title>

      <div class="tips_box">
        <img src="@/assets/images/tip.png" class="icons" />
        <div class="tips">
          <p
            class="tips_item"
          >温馨提示：1. 物流一定要有真实物流记录，要有全程重量并且重量跟真实商品一致！建议不要网上买小快递的，便宜的空包（稽查系统在不断升级）；目前可以选用平台提供的空包</p>
          <p class="tips_item">2. 推广比例一定不要过高，最高不能超过40%</p>
          <p class="tips_item">3.推广期间请务必关掉淘客、村淘、挑客，推广过程有诸多不确定因素可能导致产生佣金可能造成您的损失。</p>
        </div>
      </div>

      <div class="main_box">
        <div class="first_box">
          <el-form-item label="商品名称" prop="commodityName">
            <el-input
              placeholder="请输入商品名称"
              v-model="firstAllDateInfo.commodityName"
              class="ys"
            ></el-input>
          </el-form-item>
          <el-form-item label="商品链接" prop="commodityLink">
            <el-input
              placeholder="请输入商品链接"
              v-model="firstAllDateInfo.commodityLink"
              style="width:600px"
            ></el-input>
          </el-form-item>
          <el-form-item label="商品主图">
            <el-upload
              action=""
              list-type="picture-card"
              :limit="1"
              :auto-upload="false"
              :on-remove="handleRemove"
              :on-change="handleImageChange"
              ref="uploadImage"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="ImageDialogVisible">
              <img width="100%" :src="firstAllDateInfo.imageUrl" alt />
            </el-dialog>
          </el-form-item>



          <el-form-item label="商品规格" prop="commoditySpecifications">
            <el-input placeholder="请输入商品规格"  v-model="firstAllDateInfo.commoditySpecifications" class="ys"></el-input>
            <span class="smalltip">如：不需要指定规格请填写"随机"</span>
          </el-form-item>

          <el-form-item label="商品下单价格" prop="commodityXDPrice">
              <el-input placeholder="请输入商品下单价格" v-model="firstAllDateInfo.commodityXDPrice" class="ys">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
          <el-form-item label="每单拍" prop="MDP_Num">
              <el-input placeholder="请输入每单拍" v-model.number="firstAllDateInfo.MDP_Num" class="ys">
              <span slot="append">件</span>
            </el-input>
          </el-form-item>
          <el-form-item label="手机搜索页展示价格" prop="displayPrice">
              <el-input placeholder="请输入手机搜索页展示价格" v-model="firstAllDateInfo.displayPrice" class="ys">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
          <el-form-item label="商品现有收货人数或付款人数" prop="peopleNum">
              <el-input placeholder="请输入现有收货人数或付款人数" v-model.number="firstAllDateInfo.peopleNum" class="ys">
              <span slot="append">人</span>
            </el-input>
          </el-form-item>

          <div class="price_range">
            <el-form-item label="价格区间" >
                <el-input placeholder="请输入价格开始区间" v-model="firstAllDateInfo.priceStart" style="width:220px;margin-right:20px">
                <span slot="append">元</span>
              </el-input>
            </el-form-item>

            <div class="line">-</div>

            <el-form-item  label-width="0px">
                <el-input placeholder="请输入价格结束区间" v-model="firstAllDateInfo.priceEnd" style="width:220px;margin-left:20px">
                <span slot="append">元</span>
              </el-input>
            </el-form-item>
          </div>
        </div>

        <div class="second_box">
          <div class="coupon_box">
                 <el-form-item label="是否需要领取优惠券">
                     <el-checkbox v-model="firstAllDateInfo.couponChecked">需要用户领取优惠券下单</el-checkbox>
                     <span class="smalltip">(建议不要使用优惠券下单，更有利于宝贝做坑产，也避免用户忘记使用优惠券造成的补差价退款问题)</span>
                 </el-form-item>

                 <div class="coupon" v-if="firstAllDateInfo.couponChecked==true">
                        <p class="tit">优惠券设置</p>
                        <div class="main">
                               <div class="top">
                                      <el-radio-group v-model="firstAllDateInfo.couponRadio">
                                            <el-radio :label="1">隐藏优惠券</el-radio>
                                           <el-radio :label="2">店铺优惠券</el-radio>
                                      </el-radio-group>
                               </div>
                               <div class="bottom">
                                      <el-form-item label="优惠券金额" prop="couponmoney">
                                              <el-input placeholder="请输入优惠券金额" v-model="firstAllDateInfo.couponmoney" style="width:220px;">
                                                 <span slot="append">元</span>
                                              </el-input>   
                                      </el-form-item>

                                      <el-form-item label="优惠券到期时间" prop="coupontime">
                                              <el-date-picker v-model="firstAllDateInfo.coupontime" type="date" placeholder="选择日期" style="width:220px;"  value-format="yyyy-MM-dd"></el-date-picker>
                                      </el-form-item>

                                      <el-form-item label="优惠券链接" v-if="firstAllDateInfo.couponRadio==1" prop="couponurl">
                                              <el-input placeholder="请输入优惠券链接" v-model="firstAllDateInfo.couponurl" style="width:800px;"></el-input>   
                                      </el-form-item>
                               </div>
                        </div>
                 </div>
          </div>

          <el-form-item label="是否开启无痕任务">
            <el-checkbox
              v-model="firstAllDateInfo.tracelessChecked"
            >无痕任务（买手只上传一张付款截图，按照真实购物行为操作，安全性较高）</el-checkbox>
          </el-form-item>

          <el-form-item label="是否包邮">
            <el-radio-group v-model="firstAllDateInfo.freeShipping">
              <el-radio :label="1">是(佣金单默认包邮)</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="允许使用淘宝红包卡红包">
            <el-switch v-model="firstAllDateInfo.redEnvelopeCards"></el-switch>
          </el-form-item>

          <el-form-item label="付款方式">
            <el-checkbox-group v-model="paymentMethod">
              <el-checkbox
                :label="item.label"
                v-for="item in firstAllDateInfo.paymentMethodList"
                :key="item.id"
              ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </div>

      <Title :title="title[1]"></Title>

      <div class="find_goods_box">
        <el-form-item label="商品所在地">
          <el-select v-model="addressValue">
            <el-option
            
              v-for="item in taskConditionInfo.addressOptions"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="筛选条件（折扣服务）">
          <el-checkbox-group v-model="ServiceCheckList">
            <el-checkbox
              :label="item.label"
              v-for="item in taskConditionInfo.ServiceCheckInfo"
              :key="item.id"
            ></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>

      <div class="operation_btns">
        <el-button type="primary" class="next_step" :loading="loading" @click="nextStep">下一步</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { isNumber, isNumTwo, people } from "@/utils/validate";
import { mapState } from "vuex";
import throttle from "lodash/throttle";

export default {
  data() {
    return {
      title: ["设置推广目标宝贝信息", "设置如何找到商品"],
      //控制商品主图对话框的显示与隐藏
      ImageDialogVisible:false,
      //对话框图片路径
      dialogImageUrl:'',
      // imageformData:{},
      uploadFile:{},
      firstAllDateInfo: {
        //第一模块总数据
        //商品信息
        commodityName: "", //商品名称
        commodityLink: "", //商品链接
        imageUrl: "", //商品主图
        commoditySpecifications: "", //商品规格
        commodityXDPrice: "", //商品下单价格
        MDP_Num: "", //每单拍
        displayPrice: "", //手机搜索页展示价格
        peopleNum: "", //收款人数或付款人数
        priceStart: "", //区间价格开始
        priceEnd: "" ,//区间价格结束
        couponChecked: false, //是否需要领取优惠券
        couponmoney:'',//优惠券金额
        coupontime:'',//优惠券到期时间
        couponurl:'',//优惠券链接
        couponRadio:1,//优惠券设置默认值
        tracelessChecked: false, //是否开启无痕任务
        freeShipping: 1, //是否包邮 1包 2不包
        redEnvelopeCards: false, //是否允许使用淘宝红包卡红包
        paymentMethodList: [
          //付款方式
          {
            id: 1,
            label: "允许买家使用信用卡支付",
            isChecked: false
          },
          {
            id: 2,
            label: "允许买家使用花呗支付",
            isChecked: false
          }
        ]
      },
      paymentMethod: [],//支付方式
      addressOptions: [
        {
          value: "1",
          label: "全国"
        },
        {
          value: "2",
          label: "北京"
        },
        {
          value: "3",
          label: "上海"
        },
        {
          value: "4",
          label: "广州"
        },
        {
          value: "5",
          label: "深圳"
        }
      ],
      addressValue: "全国",
      ServiceCheckList: ["包邮"],
      //第一模块表单的验证规则对象
      firstAllRules: {
        commodityName: [
          { required: true, message: "商品名称不能为空", trigger: "blur" }
        ],
        commodityLink: [
          { required: true, message: "商品链接不能为空", trigger: "blur" }
        ],
        // imageUrl: [
        //   { required: true, message: "商品主图不能为空", trigger: "change" }
        // ],
        commoditySpecifications: [
          { required: true, message: "商品规格不能为空", trigger: "blur" }
        ],
        commodityXDPrice: [
          { required: true, message: "商品下单价格不能为空", trigger: "blur" },
          { validator: isNumTwo, trigger: "blur" }
        ],
        MDP_Num: [
          { required: true, message: "每单拍件数不能为空", trigger: "blur" },
          { validator: isNumber, trigger: "blur" }
        ],
        displayPrice: [
          { required: true, message: "展示价格不能为空", trigger: "blur" },
          { validator: isNumTwo, trigger: "blur" }
        ],
        peopleNum: [
          {
            required: true,
            message: "商品现有收货人数或付款人数不能为空",
            trigger: "blur"
          },
          { validator: people, trigger: "blur" }
        ],
        couponmoney: [
          { required: true, message: "优惠券金额不能为空", trigger: "blur" },
          { validator: isNumTwo, trigger: "blur" }
        ],
        coupontime: [
          { required: true, message: "请选择优惠券到期时间", trigger: "blur" },
        ],
        couponurl: [
          { required: true, message: "请输入优惠券链接", trigger: "blur" },
        ],
      },
      //提交表单状态
      loading:false,
    };
  },
  computed: {
    ...mapState({
      taskConditionInfo: state => state.publish.taskConditionInfo
    })
  },
  props:['taskStoreID','tasktypeid'],
  methods: {
    //获取发布模块一的商品筛选信息
    getTaskCondition() {
      this.$store.dispatch("getTaskCondition");
    },

     //文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
     handleImageChange(file,fileList){
            const isJPG = file.raw.type === 'image/jpeg'|| file.raw.type == 'image/png' || file.raw.type == 'image/jpg';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                  this.$message.error('上传图片只能是JPG/PNG/JPEG 格式!');
                  return false
            }else if (!isLt2M) {
                  this.$message.error('上传图片大小不能超过2MB!');
                  return false
            }else if (isLt2M && isJPG) {
          		this.uploadFile.img = file.raw;//图片的信息
              this.uploadFile.name = file.name;//图片的名字


              //图片上传处理方法
              // 等待照片上传成功后，清除对照片的非空校验
              // this.$nextTick(() => {//等待照片上传完成，
              //       //  this.$refs['headimgUpload'].clearValidate()
              //         this.$refs.headimgUpload.clearValidate(); // 关闭校验
              // })
             
          	}
     },

    handleRemove(file, fileList) {
        	this.uploadFile = {}
    },

 
    //下一步
    nextStep:throttle(function(){
           this.$refs.firstFormRef.validate(async valid => { 
                 if(!valid) return
                 try{
                       //图片
                       this.loading = true
                       const formData = new FormData()
                       for (const key in this.uploadFile) {
                              formData.append(key,this.uploadFile[key]);//传参改为formData格式
                       }
                        formData.append('tasktype',this.tasktypeid)
                        formData.append('shopsid',this.taskStoreID)
                        formData.append('goodsname',this.firstAllDateInfo.commodityName)
                        formData.append('goodsurl',this.firstAllDateInfo.commodityLink)
                        formData.append('goodsspecs',this.firstAllDateInfo.commoditySpecifications)
                        formData.append('goodsprice',this.firstAllDateInfo.commodityXDPrice)
                        formData.append('goodsnum',this.firstAllDateInfo.MDP_Num)
                        formData.append('showprice',this.firstAllDateInfo.displayPrice)
                        formData.append('paynum',this.firstAllDateInfo.peopleNum)
                        formData.append('small',this.firstAllDateInfo.priceStart)
                        formData.append('big',this.firstAllDateInfo.priceEnd)
                        formData.append('ifcoupon',this.firstAllDateInfo.couponChecked)
                        formData.append('coupon',this.firstAllDateInfo.couponRadio)
                        formData.append('couponmoney',this.firstAllDateInfo.couponmoney)
                        formData.append('coupontime',this.firstAllDateInfo.coupontime)
                        formData.append('couponurl',this.firstAllDateInfo.couponurl)
                        formData.append('traceless',this.firstAllDateInfo.tracelessChecked)
                        formData.append('iffreeshipping',this.firstAllDateInfo.freeShipping)
                        formData.append('ifkb',this.firstAllDateInfo.redEnvelopeCards)
                        formData.append('paymethod',this.paymentMethod)
                        formData.append('goodslocation',this.addressValue)
                        formData.append('screen',this.ServiceCheckList)
                        await this.$store.dispatch('publishTaskFirstModuleSubmit',formData)
                        this.loading = false
                        this.$emit("changeStepsActive", 1);
                 }catch(error){
                        this.$message.warning(error.message)
                        this.loading = false
                 }
               
             })
    },1000)






    
  },
  created() {
    this.getTaskCondition();
  }
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  .tips_box {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    .icons {
      width: 30px;
      height: 15px;
    }
  }
  .main_box {
    margin-bottom: 20px;
    .first_box {
      height: auto;
      border: 1px solid #e4e7ed;
      margin-top: 15px;
      margin-bottom: 10px;
      padding: 15px;
      background: #f4f5f6;
    }
    .second_box {
      height: auto;
      border: 1px solid #e4e7ed;
      margin-top: 15px;
      margin-bottom: 50px;
      padding: 15px;
      background: #f4f5f6;
    }
  }
  .find_goods_box {
    margin-bottom: 20px;
    height: auto;
    border: 1px solid #e4e7ed;
    margin-top: 15px;
    margin-bottom: 50px;
    padding: 15px;
    background: #f4f5f6;
  }
  .operation_btns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .next_step {
      width: 300px;
    }
  }
}

.price_range {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  .line {
    margin-top: 10px;
  }
}

.tips_item {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #a2a2a2;
}
.ys {
  width: 300px;
}
.smalltip {
  margin-left: 20px;
  color: #d40b35;
  font-size: 16px;
  font-weight: 500;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.coupon{
     height:auto;
     border:1px solid #409eff;
     margin:0 80px 0;
     padding:15px  30px;
     .tit{
        font-size:18px;
        font-weight:600;
     }
     .main{
        margin-top:20px;
        .top{

        }
        .bottom{
            margin-top:20px;
        }
     }
}
</style>