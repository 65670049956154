<template>
  <div class="QRCodeSearch">
    <el-form status-icon label-width="150px" :model="thirdAllData" ref="thirdForm">
    <div class="content">
      <div class="content_top">
        <!-- <el-form-item label="设置订单总数" label-width="120px" prop="thirdTotalOrders" :rules="{ required: true, validator:orderNum, trigger: 'blur' }">
          <el-input style="width:200px" v-model.number="thirdAllData.thirdTotalOrders"></el-input>
          <span class="s_tip" v-if="thirdAllData.taskTypeRadio!==1">（指定任务设置不能大于10单）</span>
          <span class="p_tip">（总订单数为所有设置订单数的总和，多流量入口，提升优化效果！请详细填写）</span>
        </el-form-item> -->

        <el-form-item label="二维码图片上传" label-width="120px">
              <el-upload
                     action=""
                     list-type="picture-card"
                     :limit="1"
                     :auto-upload="false"
                      multiple
                     :on-remove="(file, fileList) => {codeRemove(file,fileList)}"
                     :on-change="(file, fileList) => {codeChange(file,fileList)}"
              >
              <i class="el-icon-plus"></i>
            </el-upload>
        </el-form-item>
      </div>

      <div class="content_bottom">
        <el-form-item label="任务类型" label-width="100px">
          <el-radio-group v-model="thirdAllData.taskTypeRadio" @change="changeTaskTypeRadio">
            <el-radio :label="1">普通任务</el-radio>
            <el-radio :label="2">指定文字好评任务（每单1.00元）</el-radio>
            <el-radio :label="3">指定图片好评任务（每单1.00元）</el-radio>
            <el-radio :label="4">指定图文好评任务（每单2.00元）</el-radio>
          </el-radio-group>
        </el-form-item>

        <p class="b_tit">设置商品链接单数相加必须等于总单数</p>

        <div class="bottom_main" v-for="(item,index) in thirdAllData.thirdValue" :key="item.id">
          <el-form-item v-if="thirdAllData.taskTypeRadio==1" label="设置订单总数" label-width="120px" prop="thirdTotalOrders" :rules="{ required: true, validator:orderNum, trigger: 'blur' }">
                 <el-input style="width:200px" v-model.number="thirdAllData.thirdTotalOrders" @input="changeNum"></el-input>
                 <span class="main_p">普通任务默认5星好评。</span>
          </el-form-item> 

          <div class="main_bigbox" v-else-if="thirdAllData.taskTypeRadio==2">
             <div class="main_top">
              <div class="index">{{index+1}}</div>
              <div class="nr_box">
                 <div class="nr_f">
                       <el-form-item label="设置单数" label-width="180px" :prop="`thirdValue[${index}].num`" :rules="{ required: true, validator:orderNum, trigger: 'blur' }">
                          <el-input style="width:100px" v-model.number="item.num"  @input="(val)=>changeAllOrder(val,item.id)"></el-input>
                      </el-form-item>
  
                      <el-button type="danger" class="delete" v-if="index!==0" @click="deleteSet(item.id)">删除</el-button>
                 </div>

                <div class="nr_s">
                  <el-form-item label="设置指定文字好评内容" label-width="180px">
                    <div class="submain_index">
                      <span>设置第</span>
                      <div class="s_index">{{index+1}}</div>
                      <span>单的文字</span>
                    </div>
                    <el-input type="textarea" :rows="2" placeholder="请输入文字好评内容" v-model="item.DYD_text"></el-input>
                  </el-form-item>
                </div>
              </div>
             </div>
             
          </div>

          <div class="main_bigbox" v-else-if="thirdAllData.taskTypeRadio==3">
             <div class="main_top">
              <div class="index">{{index+1}}</div>
              <div class="nr_box">
                 <div class="nr_f">
                       <el-form-item label="设置单数" label-width="180px" :prop="`thirdValue[${index}].num`" :rules="{ required: true, validator:orderNum, trigger: 'blur' }">
                          <el-input style="width:100px" v-model.number="item.num" @input="(val)=>changeAllOrder(val,item.id)"></el-input>
                      </el-form-item>
  
                      <el-button type="danger" class="delete" v-if="index!==0" @click="deleteSet(item.id)">删除</el-button>
                 </div>

                <div class="nr_s">
                  <el-form-item label="设置指定图片好评" label-width="180px">
                    <div class="submain_index">
                      <span>设置第</span>
                      <div class="s_index">{{index+1}}</div>
                      <span>单的图片</span>
                    </div>
                      <el-upload
                             action=""
                             list-type="picture-card"
                             :limit="5"
                             :auto-upload="false"
                              multiple
                             :on-remove="(file, fileList) => {thirdHandleRemove(file,fileList,item.id)}"
                             :on-change="(file, fileList) => {thirdHandleImageChange(file,fileList,item.id)}"
                         >
                        <i class="el-icon-plus"></i>
                     </el-upload>
                     <p class="tips">（最多仅支持上传五张图片，图片中不要显示店铺名或商品名，避免用户直接搜索。单张图片大小不能超过2MB，图片格式可以为JPG、PNG、JPEG）</p>
                  </el-form-item>
                </div>
              </div>
             </div>
             
          </div>

          <div class="main_bigbox" v-else-if="thirdAllData.taskTypeRadio==4">
             <div class="main_top">
              <div class="index">{{index+1}}</div>
              <div class="nr_box">
                 <div class="nr_f">
                       <el-form-item label="设置单数" label-width="180px" :prop="`thirdValue[${index}].num`" :rules="{ required: true, validator:orderNum, trigger: 'blur' }">
                          <el-input style="width:100px" v-model.number="item.num"  @input="(val)=>changeAllOrder(val,item.id)"></el-input>
                      </el-form-item>
  
                      <el-button type="danger" class="delete" v-if="index!==0" @click="deleteSet(item.id)">删除</el-button>
                 </div>

                <div class="nr_s">
                   <el-form-item label="设置指定文字好评内容" label-width="180px">
                    <div class="submain_index">
                      <span>设置第</span>
                      <div class="s_index">{{index+1}}</div>
                      <span>单的文字</span>
                    </div>
                    <el-input type="textarea" :rows="2" placeholder="请输入文字好评内容" v-model="item.DYD_text"></el-input>
                  </el-form-item>

                  <el-form-item label="设置指定文字好评内容" label-width="180px">
                    <div class="submain_index">
                      <span>设置第</span>
                      <div class="s_index">{{index+1}}</div>
                      <span>单的图片</span>
                    </div>
                      <el-upload
                             action=""
                             list-type="picture-card"
                             :limit="5"
                             :auto-upload="false"
                              multiple
                             :on-remove="(file, fileList) => {thirdHandleRemove(file,fileList,item.id)}"
                             :on-change="(file, fileList) => {thirdHandleImageChange(file,fileList,item.id)}"
                         >
                        <i class="el-icon-plus"></i>
                     </el-upload>
                     <p class="tips">（最多仅支持上传五张图片，图片中不要显示店铺名或商品名，避免用户直接搜索。单张图片大小不能超过2MB，图片格式可以为JPG、PNG、JPEG）</p>
                  </el-form-item>
                </div>
              </div>
             </div>
             
          </div>

        </div>

        <el-button type="primary" class="haowu hw-add setbtn" @click="addSet" v-if="thirdAllData.taskTypeRadio!==1">&nbsp;&nbsp;设置第{{getNextOrderNumber}}单</el-button>
      </div>
    </div>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //第三模块总数据
      thirdAllData:{
            //任务类型默认值
            taskTypeRadio: 1,
            thirdItemID:1,
            thirdTotalOrders:1,//订单总数
            codeImage:{},//二维码图片
            thirdValue:[
                {
                    id:1,
                    num:1,  //设置单数
                    DYD_text:'', //设置第n单的文字
                    imageList:[],//图片集合
                }
            ],
      }
    };
  },
  computed:{
      getNextOrderNumber(){
          return this.thirdAllData.thirdValue.length + 1
      }
  },
  methods: {
    //切换任务类型
    changeTaskTypeRadio(val) {
      this.thirdAllData.taskTypeRadio = val;
      this.thirdAllData.thirdItemID = 1;
      this.thirdAllData.thirdValue = [
          {
              id:1,
              num:1,  //设置单数
              DYD_text:'', //设置第n单的文字
              imageList:[],//图片集合
          }
      ];
      this.thirdAllData.thirdTotalOrders = 1
      this.$emit('changeAllOrder',1)
    },

    //增加设置单数
    addSet(){
          let allNum = 0 
          this.thirdAllData.thirdValue.forEach(item=>{
                 allNum += item.num
          })
          if(allNum<10000){
                    this.thirdAllData.thirdItemID += 1
                    if(this.thirdAllData.taskTypeRadio==2){
                          if(this.thirdAllData.thirdValue.length < 10){
                               this.thirdAllData.thirdValue.push({id:this.thirdAllData.thirdItemID,num:1,DYD_text:'',imageList:[]})
                          }else{
                               this.$message.warning('最多设置10个')
                          }
                    }else if(this.thirdAllData.taskTypeRadio==3){
                          if(this.thirdAllData.thirdValue.length < 10){
                               this.thirdAllData.thirdValue.push({id:this.thirdAllData.thirdItemID,num:1,DYD_text:'',imageList:[]})
                          }else{
                               this.$message.warning('最多设置10个')
                          }
                    }else if(this.thirdAllData.taskTypeRadio==4){
                          if(this.thirdAllData.thirdValue.length < 10){
                               this.thirdAllData.thirdValue.push({id:this.thirdAllData.thirdItemID,num:1,DYD_text:'',imageList:[]})
                          }else{
                               this.$message.warning('最多设置10个')
                          }
                    }
          }else{
                   this.$message.warning("总订单数不能超过10000单！");
          }
        
    },


    //删除设置的单数
    deleteSet(id){
         this.thirdAllData.thirdValue.splice(this.thirdAllData.thirdValue.findIndex(item => item.id === id), 1)
    },

    //二维码图片切换
    codeChange(file,fileList){
          const isJPG = file.raw.type === 'image/jpeg'|| file.raw.type == 'image/png' || file.raw.type == 'image/jpg';
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isJPG) {
                  this.$message.error('上传二维码只能是JPG/PNG/JPEG 格式!');
                  return false
           }else if (!isLt2M) {
                  this.$message.error('上传二维码大小不能超过2MB!');
                  return false
          }else if (isLt2M && isJPG) {
                  this.thirdAllData.codeImage = file.raw
          }
    },


    //二维码图片删除
    // codeRemove(){
    //      this.codeImage = {}
    // },

    
    //文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    thirdHandleImageChange(file,fileList,id){
            const isJPG = file.raw.type === 'image/jpeg'|| file.raw.type == 'image/png' || file.raw.type == 'image/jpg';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                  this.$message.error('上传图片只能是JPG/PNG/JPEG 格式!');
                  return false
            }else if (!isLt2M) {
                  this.$message.error('上传图片大小不能超过2MB!');
                  return false
            }else if (isLt2M && isJPG) {
                    this.thirdAllData.thirdValue.forEach(item=>{
                          if(item.id==id){
                              item.imageList.push(file)
                          }
                    })
          	}
     },

    //删除图片
     thirdHandleRemove(file,fileList,id) {
        const uid = file.uid
        this.thirdAllData.thirdValue.forEach(item=>{
             if(item.id==id){
                  item.imageList.splice(item.imageList.findIndex(i=>i.uid == uid),1)
             }
        })
     },


    //搜索关键词
    allSpaces(rule, value, cb){
        //验证输入全部为空格的正则
        const regAllSpaces = /^[ ]+$/;
        if(value){
              if (regAllSpaces.test(value)) {
                    return cb(new Error('搜索关键词不能全为空'));
              }else{
                    cb();
              }
        }else{
               return cb(new Error('搜索关键词不能为空'));
        }
        
    },

    //单数
    orderNum(rule,value,cb){
          if (!value) {
             return cb(new Error('输入不能为空'));
         }else{
             setTimeout(() => {
               if (!Number.isInteger(value)) {
                  cb(new Error('请输入数字值'));
               } else {
                 if (value<=0) {
                   cb(new Error('请输入正整数'))
                 } else {
                   cb();
                 }
               }
             }, 1000);
         }
   },

    validate(callback){
      this.$refs.thirdForm.validate((valid) => {
        callback(valid);
      });
    },

    //改变总单数方式一
    changeNum(val){
        if(this.thirdAllData.thirdTotalOrders<10000){
               this.$emit('changeAllOrder',this.thirdAllData.thirdTotalOrders)
        }else{
               this.$message.warning('总订单数不能超过10000单！')
               this.thirdAllData.thirdTotalOrders = 10000
               this.$emit('changeAllOrder',10000)
        }
    },

    //改变总订单数方式二
    changeAllOrder(val,id){
        let allNum = 0
        let otherNum = 0
        this.thirdAllData.thirdValue.forEach(item=>{
              allNum  += item.num
        })
        if(allNum<=10000){
             this.$emit('changeAllOrder',allNum)
        }else{
             this.$message.warning('总订单数不能超过10000单！')
             this.thirdAllData.thirdValue.forEach(item=>{               
                    if(item.id!==id){
                          otherNum += item.num
                    }
             })
             this.thirdAllData.thirdValue.forEach(item=>{               
                    if(item.id==id){
                          item.num = 10000 - otherNum 
                    }
             })
            this.$emit('changeAllOrder',10000)
        }
        
    },


    //收集第三小模块数据
    collectionThird(){
         let thirddata = {}
         thirddata.taskTypeRadio = this.thirdAllData.taskTypeRadio
         thirddata.thirdTotalOrders = this.thirdAllData.thirdTotalOrders
         thirddata.codeImage = this.thirdAllData.codeImage
         thirddata.thirdValue = this.thirdAllData.thirdValue
         return thirddata
    }
  },
   watch: {
            'thirdAllData.thirdValue.length': {
                handler(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        // 操作
                        this.changeAllOrder()
                    }
                }
            }
  }
};
</script>

<style lang="less" scoped>
// @assets:"~@/assets";
// @import "@{assets}/css/theme.less";
.QRCodeSearch {
  height: auto;
  border: 1px solid rgba(@themeColor,1);
  padding: 15px;
  .content {
    .content_top {
      height: auto;
    }
    .content_bottom {
      height: auto;
      border: 1px solid rgba(@themeColor,1);
      padding: 10px;
      .b_tit {
        color: #d40b35;
        margin: 0 30px 0;
      }
      .bottom_main {
         margin-top:20px;
        .main_p {
          color: #a2a2a2;
          font-size: 12px;
          line-height: 16px;
          margin: 10px 30px 0;
        }
        .main_bigbox {
          .main_top {
            border: 1px solid rgba(@themeColor,1);
            height: auto;
            margin: 0 30px 0;
            position: relative;
            .index {
              background:rgba(@themeColor,1);
              color:#ffffff;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              line-height: 26px;
              text-align: center;
              margin-right: 10px;
              position: absolute;
              left: -10px;
              top: -10px;
            }
            .nr_box {
              padding: 15px;
              .nr_f{
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                  .delete{
                        margin-left:30px;
                  }
              }
              .nr_s{
                width: 100%;
                height: auto;
                .submain_index {
                  display: flex;
                  justify-content: start;
                  align-items: center;
                  font-size: 18px;
                  font-weight: 600;
                  .s_index {
                    background:rgba(@themeColor,1);
                    color:#ffffff;
                    width: 26px;
                    height: 26px;
                    border-radius: 50%;
                    line-height: 26px;
                    text-align: center;
                    margin: 0 5px 0;
                  }
                }
              }
            }
          }
        }
      }
      .setbtn{
          margin:20px 30px 20px;
      }
    }
  }
}
.s_tip {
  color: #d40b35;
  font-size: 13px;
  line-height: 16px;
  margin-top: 10px;
  margin-left: 20px;
}
.p_tip {
  color: #a2a2a2;
  font-size: 13px;
  line-height: 16px;
}
</style>