<template>
  <div>
    <!-- 面包屑区域 -->
    <Breadcrumb :breadcrumbData="breadcrumbData"></Breadcrumb>

    <el-card shadow="hover">
      <stepBar :stepsActive="stepsActive" />
      <firstStep v-if="stepsActive==0" @changeStepsActive="changeStepsActive" :tasktypeid="tasktypeid" :taskStoreID="taskStoreID"/>
      <thirdStep v-else-if="stepsActive==1"  @changeStepsActive="changeStepsActive"/>
      <secondStep v-else @changeStepsActive="changeStepsActive"/>
    </el-card>
  </div>
</template>

<script>
import stepBar from "./stepBar/stepBar";
import firstStep from "./firstStep/firstStep";
import secondStep from "./secondStep/secondStep";
import thirdStep from './thirdStep/thirdStep'

export default {
  data() {
    return {
      title: [
        "设置推广目标宝贝信息",
        "设置如何找到商品",
        "设置任务类型和单数",
        "发布订单设置",
        "选择免费业务",
        "选择付费增值业务",
        "商家附加要求"
      ],
      //面包屑数据
      breadcrumbData: [
        {
          id: 1,
          title: "好物电商",
          path: "",
          isClick: false
        },
        {
          id: 2,
          title: "任务管理",
          path: "",
          isClick: false
        },
        {
          id: 3,
          title: "发布任务",
          path: "publish",
          isClick: true
        },
        {
          id: 4,
          title: "垫付任务发布",
          path: "fillincommodityinfo",
          isClick: true
        }
      ],
      //步骤条默认值
      stepsActive:0,
      //传进来的任务分类ID
      tasktypeid:-1,
      //传进来的店铺ID
      taskStoreID:-1
    };
  },
  methods:{
      //修改步骤条
      changeStepsActive(val){
          this.stepsActive = val
      }
  },
  components: {
    stepBar,
    firstStep,
    secondStep,
    thirdStep
  },
  created(){
      // this.taskStoreID = this.$route.query.taskStoreID
      // this.tasktypeid = this.$route.query.tasktypeid

      this.taskStoreID = JSON.parse(localStorage.getItem('taskInfo')).taskStoreID
      this.tasktypeid =  JSON.parse(localStorage.getItem('taskInfo')).tasktypeid
  }
};
</script>

<style lang="less" scoped>
.el-card {
  margin: 20px 20px 50px 20px;
}
</style>