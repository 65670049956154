<template>
  <div class="content">
    <!-- <el-form status-icon label-width="150px"> -->
      <Title :title="title[0]"></Title>

      <div class="external_box">
        <!-- <el-form-item label="设置任务类型"> -->
          <div class="setTask_box">
                <span class="set_title">设置任务类型</span>
                <el-radio-group v-model="setTaskTypeRadio" @change="changeSetTaskTypeRadio">
                        <el-radio :label="1">移动端搜索关键词</el-radio>
                        <el-radio :label="2">商品链接搜索</el-radio>
                        <el-radio :label="3">二维码搜索搜索</el-radio>
                </el-radio-group>
          </div>
        <!-- </el-form-item> -->

        <searchKeywords v-if="setTaskTypeRadio==1" ref="searchKeywords" @changeAllOrder="changeAllOrder"/>
        <commodityLinkSearch v-else-if="setTaskTypeRadio==2" ref="commodityLinkSearch" @changeAllOrder="changeAllOrder"/>
        <QRCodeSearch v-else ref="QRCodeSearch" @changeAllOrder="changeAllOrder"/>
        
      </div>

      <Title :title="title[1]"></Title>
      
      <publishOrderSettings :allNum="allNum" ref="publishOrderSettings"/>

      <div class="operation_btns">
            <el-button type="primary" class="next_step" :loading="loading" @click="nextStep">下一步</el-button>
      </div>
    <!-- </el-form> -->
  </div>
</template>

<script>
import throttle from "lodash/throttle";
import { mapState } from 'vuex'
import searchKeywords from './searchKeywords/searchKeywords'
import commodityLinkSearch from './commodityLinkSearch/commodityLinkSearch'
import QRCodeSearch from './QRCodeSearch/QRCodeSearch'
import publishOrderSettings from './publishOrderSettings/publishOrderSettings'
export default {
  data() {
    return {
      title: ["设置任务类型和单数", "发布订单设置"],
      //设置任务类型默认值
      setTaskTypeRadio: 1,
      allNum:1,//总订单数
      //提交表单状态
      loading:false,
    };
  },
  computed:{
      ...mapState({
          secondBhCode:state => state.publish.secondBhCode
      })
  },
  components:{
      searchKeywords,
      commodityLinkSearch,
      QRCodeSearch,
      publishOrderSettings
  },
  methods:{
     //切换设置任务类型
     changeSetTaskTypeRadio(val){
          this.setTaskTypeRadio = val
          this.allNum = 1
     },

    //改变总单数
     changeAllOrder(val){
          this.allNum = val
     },

     //下一步
    nextStep:throttle(function(){
        const formData = new FormData()
        if(this.setTaskTypeRadio==1){
                     const p1 = new Promise((resolve, reject) => {
                          this.$refs.searchKeywords.validate((valid) => {
                            if (valid) resolve();
                          });
                     });
           
                     const p2 = new Promise((resolve, reject) => {
                         this.$refs.publishOrderSettings.validate((valid) => {
                           if (valid) resolve();
                         });
                     });
           
                     Promise.all([p1, p2]).then(async () => {
                         //全部验证通过就会走这里
                          let firstData = this.$refs.searchKeywords.collectionFirst()
                          let fourthData = this.$refs.publishOrderSettings.collectionFourth()
                         //  let bh ='T11648176986835219'
                          formData.append('bh',this.secondBhCode);
                          formData.append('setTaskTypeRadio',this.setTaskTypeRadio)
                          formData.append('firstValue',JSON.stringify(firstData.firstValue))
                          formData.append('firstTaskTypeRadio',firstData.taskTypeRadio)
                          firstData.firstValue.forEach((item,index) =>{
                              item.imageList.forEach(i=>{
                                   formData.append('firstFileList'+(index+1)+'[]', i) 
                              })
                          })
                          formData.append('allNum',fourthData.allNum)
                          formData.append('hour',fourthData.hour)
                          formData.append('planValue',JSON.stringify(fourthData.planValue))

                          try{
                                this.loading = true
                                await this.$store.dispatch('publishTaskThirdModuleSubmit',formData)
                                this.loading = false
                                this.$router.replace({path:'/settlement'})
                          }catch(error){
                                this.$message.warning(error.message)
                                this.loading = false
                          }
                         
                    });
        }else if(this.setTaskTypeRadio==2){
                    const p1 = new Promise((resolve, reject) => {
                        this.$refs.commodityLinkSearch.validate((valid) => {
                            if (valid) resolve();
                        });
                    });
        
                    const p2 = new Promise((resolve, reject) => {
                        this.$refs.publishOrderSettings.validate((valid) => {
                            if (valid) resolve();
                        });
                    });
        
                    Promise.all([p1, p2]).then(async () => {
                        //全部验证通过就会走这里
                        let secondData = this.$refs.commodityLinkSearch.collectionSecond()
                        let fourthData = this.$refs.publishOrderSettings.collectionFourth()
                        // let bh ='T11648176986835219'
                        formData.append('bh',this.secondBhCode);
                        formData.append('setTaskTypeRadio',this.setTaskTypeRadio)
                        formData.append('secondValue',JSON.stringify(secondData.secondValue))
                        formData.append('secondTotalOrders',secondData.secondTotalOrders)
                        formData.append('secondTaskTypeRadio',secondData.taskTypeRadio)
                        formData.append('commodityLink',secondData.commodityLink)
                        secondData.secondValue.forEach((item,index) =>{
                             item.imageList.forEach(i=>{
                                  formData.append('secondFileList'+(index+1)+'[]', i) 
                             })
                        })
                        formData.append('allNum',fourthData.allNum)
                        formData.append('hour',fourthData.hour)
                        formData.append('planValue',JSON.stringify(fourthData.planValue))
                        try{
                             this.loading = true
                             await this.$store.dispatch('publishTaskThirdModuleSubmit',formData)
                             this.loading = false
                             this.$router.replace({path:'/settlement'})
                        }catch(error){
                             this.$message.warning(error.message)
                             this.loading = false
                        }
                       
                   });
        }else{
                      let thirddata = this.$refs.QRCodeSearch.collectionThird()
                      const p1 = new Promise((resolve, reject) => {
                          this.$refs.QRCodeSearch.validate((valid) => {
                            if (valid) resolve();
                          });
                      });
          
                      const p2 = new Promise((resolve, reject) => {
                          this.$refs.publishOrderSettings.validate((valid) => {
                            if (valid) resolve();
                          });
                      });
          
                      Promise.all([p1, p2]).then(async () => {
                        //全部验证通过就会走这里
                         let thirdData = this.$refs.QRCodeSearch.collectionThird()
                         let fourthData = this.$refs.publishOrderSettings.collectionFourth()
                        //  let bh ='T11648176986835219'
                         formData.append('bh',this.secondBhCode);
                         formData.append('setTaskTypeRadio',this.setTaskTypeRadio)
                         formData.append('thirdValue',JSON.stringify(thirdData.thirdValue))
                         formData.append('thirdTotalOrders',thirdData.thirdTotalOrders)
                         formData.append('thirdTaskTypeRadio',thirdData.taskTypeRadio)
                         formData.append('codeImage',thirdData.codeImage)
                         thirdData.thirdValue.forEach((item,index) =>{
                             item.imageList.forEach(i=>{
                                  formData.append('thirdFileList'+(index+1)+'[]', i.raw) 
                             })
                         })
                        formData.append('allNum',fourthData.allNum)
                        formData.append('hour',fourthData.hour)
                        formData.append('planValue',JSON.stringify(fourthData.planValue))
                        try{
                             this.loading = true
                             await this.$store.dispatch('publishTaskThirdModuleSubmit',formData)
                             this.loading = false
                             this.$router.replace({path:'/settlement'})
                        }catch(error){
                             this.$message.warning(error.message)
                             this.loading = false
                        }
                      
                     });
        }
        
    },1000)

  
      
  }

};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  .external_box {
    margin-top: 15px;
    margin-bottom: 30px;
    padding: 15px;
    background: #f4f5f6;
    border: 1px solid #e4e7ed;
    .setTask_box{
        display:flex;
        justify-content:flex-start;
        align-items:center;
        margin:20px;
        .set_title{
           margin-right:30px;
        }
    }
  }
  .operation_btns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:50px;
    .next_step {
      width: 300px;
    }
  }
}

</style>