<template>
  <div class="addedServices">
    <el-checkbox-group v-model="isCheckedAddedServices">
         <div class="list" v-for="item in addedServicesList" :key="item.id">
               <div class="item">
                     <el-checkbox :label="item.label"  @change="changeAddedServices(item.id,item.isChecked)"></el-checkbox>
                     <div v-if="item.tip!==''" class="tip">{{item.tip}}</div>
                     <div v-if="item.money!==''" class="money">{{item.money}}</div>
               </div>
               <div class="wxts_box" v-if="item.id==5">
                       <img src="@/assets/images/tip.png" />
                       <span class="wxts">温馨提示：系统默认用户做任务不用上传关键词截图，若需要用户上传关键词截图可以勾选。强制设置用户上传截图，可能会影响用户积极性）</span>
               </div>
               
               <div class="sub_box" v-if="item.id==3&&item.isChecked==true">
                     <el-form-item label="每单加赏">
                          <el-input placeholder="请输入金额" v-model="PaymentServiceInfo.reward" class="ys">
                               <span slot="append">元</span>
                          </el-input>
                     </el-form-item>
               </div>
               <div class="sub_box" v-if="item.id==5&&item.isChecked==true">
                     <el-form-item label="设置执行人数">
                          <el-input placeholder="请输入人数" v-model="PaymentServiceInfo.people" class="ys">
                               <span slot="append">人</span>
                          </el-input>
                     </el-form-item>
               </div>
               <div class="sub_box" v-if="item.id==6&&item.isChecked==true">
                      <div class="sub_main" v-for="item in PaymentServiceInfo.collectionDesignated" :key="item.id">
                              <el-form-item label="收藏宝贝名称">
                                   <el-input placeholder="请输入宝贝名称" v-model="item.name" class="ys"></el-input>
                                   <el-button type="danger"  icon="el-icon-delete" size="medium" style="margin-left:50px" v-if="item.id!==1" @click="deleteCollectionDesignated(item.id)">删除</el-button>
                              </el-form-item>

                              <el-form-item label="上传宝贝主图" label-width="180px">
                                     <el-upload
                                           action=""
                                           list-type="picture-card"
                                           :limit="1"
                                           :auto-upload="false"
                                           :on-remove="(file, fileList) => {firstHandleRemove(file, fileList, item.id)}"
                                           :on-change="(file, fileList) => {firstHandleImageChange(file, fileList, item.id)}"
                                           ref="uploadImage"
                                    >
                                    <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <p class="tips">（单张图片大小不能超过2MB，图片格式可以为JPG、PNG、JPEG）</p>
                             </el-form-item>


                      </div>
                      <el-button type="primary" class="haowu hw-add" @click="addExtraCollection">&nbsp;&nbsp;添加额外收藏宝贝</el-button>
               </div>
               <div class="sub_box" v-if="item.id==8&&item.isChecked==true">
                      <div class="sub_main" v-for="item in PaymentServiceInfo.searchKeywords" :key="item.id">
                             <el-form-item label="搜索关键词">
                                  <el-input placeholder="请输入搜索关键词" v-model="item.text" class="ys" style="margin-right:10px"></el-input>
                                  <el-button type="danger"  icon="el-icon-delete" size="small" @click="deleteSearchKeywords(item.id)" v-if="item.id!=1">删除</el-button>
                                  <el-button type="primary" icon="el-icon-plus" size="small" @click="addSearchKeywords">添加</el-button>
                            </el-form-item>
                      </div>
               </div>
               <div class="sub_box" v-if="item.id==9&&item.isChecked==true">
                    <el-form-item label="设置单数">
                          <el-input placeholder="请输入单数" v-model="PaymentServiceInfo.jphb.ordernum" class="ys">
                               <span slot="append">单</span>
                          </el-input>
                     </el-form-item>
                    <el-form-item label="淘口令">
                         <el-input type="textarea" autosize placeholder="请输入淘口令"  v-model="PaymentServiceInfo.jphb.tkl" style="width:500px"></el-input>
                     </el-form-item>
               </div>
               <div class="sub_box" v-if="item.id==10&&item.isChecked==true">
                      <div class="sub_main" v-for="item in PaymentServiceInfo.specifyBrowse" :key="item.id">
                              <el-form-item label="指定宝贝名称">
                                   <el-input placeholder="请输入宝贝名称" v-model="item.name" class="ys"></el-input>
                                   <el-button type="danger"  icon="el-icon-delete" size="medium" style="margin-left:50px" v-if="item.id!==1" @click="deleteSpecifyBrowse(item.id)">删除</el-button>
                              </el-form-item>

                              <el-form-item label="上传宝贝主图" label-width="180px">
                                     <el-upload
                                           action=""
                                           list-type="picture-card"
                                           :limit="1"
                                           :auto-upload="false"
                                           :on-remove="(file, fileList) => {secondHandleRemove(file, fileList, item.id)}"
                                           :on-change="(file, fileList) => {secondHandleImageChange(file, fileList, item.id)}"
                                           ref="uploadImage"
                                    >
                                    <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <p class="tips">（单张图片大小不能超过2MB，图片格式可以为JPG、PNG、JPEG）</p>
                             </el-form-item>
                      </div>
                      <el-button type="primary" class="haowu hw-add" @click="addSpecifyBrowse">&nbsp;&nbsp;添加额外副宝贝</el-button>
               </div>
               <div class="sub_box" v-if="item.id==12&&item.isChecked==true">
                       <el-checkbox-group v-model="isCheckedcrowdLabel">
                          <div class="list" v-for="item in crowdLabel" :key="item.id">
                                <div class="item">
                                      <el-checkbox :label="item.label"  @change="changeCrowdLabel(item.id)"></el-checkbox>
                                      <span class="money">{{item.money}}</span>
                                </div>
                                 
                                 <div class="sub_list" v-if="item.id==1&&item.isChecked==true">
                                           <el-radio-group v-model="item.sexValue">
                                               <el-radio :label="i.label" v-for="i in item.children" :key="i.id"></el-radio>
                                           </el-radio-group>
                                 </div>

                                 <div class="sub_list" v-if="item.id==2&&item.isChecked==true">
                                           <el-radio-group v-model="item.ageValue">
                                               <el-radio :label="i.label" v-for="i in item.children" :key="i.id"></el-radio>
                                           </el-radio-group>
                                 </div>

                                 <div class="sub_list" v-if="item.id==3&&item.isChecked==true">
                                           <div class="area_box" v-for="i in item.children" :key="i.areaid">
                                                   <el-checkbox :indeterminate="i.isIndeterminate" v-model="i.checkAll" :label="i.name"  :key="i.areaid" @change="handleCheckAllChange(i.isIndeterminate,i.areaid)"></el-checkbox>

                                                   <el-checkbox-group v-model="i.isCheckedList" @change="handleCheckedCitiesChange(i.areaChildren,i.areaid)" class="area_main">
                                                          <el-checkbox v-for="city in i.areaChildren" :label="city.label" :key="city.id" @change="changeCheckedCities(i.areaid,city.id)"></el-checkbox>
                                                   </el-checkbox-group>
                                           </div>
                                 </div>

                          </div>
                      </el-checkbox-group>
               </div>
                <div class="sub_box" v-if="item.id==13&&item.isChecked==true">
                      <el-checkbox-group v-model="isCheckedOccupation">
                          <div class="list">
                                 <el-checkbox :label="item.label" v-for="item in occupationList" :key="item.id"></el-checkbox>
                          </div>
                     </el-checkbox-group>
               </div>
                <div class="sub_box" v-if="item.id==14&&item.isChecked==true">
                     <el-checkbox-group v-model="isCheckedAccountCategory">
                          <div class="list">
                                 <el-checkbox :label="item.label" v-for="item in accountCategoryList" :key="item.id"></el-checkbox>
                          </div>
                     </el-checkbox-group>
               </div>
         </div>
    </el-checkbox-group>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      isCheckedAddedServices:[],
      //职业
      occupationList:[
           {
              id:1,
              label:'学生',
              isChecked: false
           },
           {
              id:2,
              label:'职业白领',
              isChecked: false
           },
           {
              id:3,
              label:'技术蓝领',
              isChecked: false
           },
           {
              id:4,
              label:'家庭主妇',
              isChecked: false
           },
           {
              id:5,
              label:'专职司机',
              isChecked: false
           },
           {
              id:6,
              label:'公务员',
              isChecked: false
           },
           {
              id:7,
              label:'自由职业',
              isChecked: false
           },
           {
              id:8,
              label:'餐饮行业',
              isChecked: false
           },
           {
              id:9,
              label:'互联网行业',
              isChecked: false
           }
      ],
      isCheckedOccupation:[],
      //买家账号类目
      accountCategoryList:[
          {
               id:1,
               label:'服装鞋包',
               isChecked:false
          },
          {
               id:2,
               label:'手机数码',
               isChecked:false
          },
          {
               id:3,
               label:'家用电器',
               isChecked:false
          },
          {
               id:4,
               label:'美女饰品',
               isChecked:false
          },
          {
               id:5,
               label:'母婴用品',
               isChecked:false
          },
          {
               id:6,
               label:'家居建材',
               isChecked:false
          },
          {
               id:7,
               label:'百货食品',
               isChecked:false
          },
          {
               id:8,
               label:'运动户外',
               isChecked:false
          },
          {
               id:9,
               label:'文化娱乐',
               isChecked:false
          },
          {
               id:10,
               label:'生活服务',
               isChecked:false
          },
          {
               id:11,
               label:'汽摩配件',
               isChecked:false
          },
          {
               id:12,
               label:'游戏话费',
               isChecked:false
          }
      ],
      //选择卖家账号类目
      isCheckedAccountCategory:[],
      //人群标签
      crowdLabel:[
             {
                id:1,
                label:'性别',
                money:'0.50元',
                sexValue:'',
                isChecked:false,
                children:[
                    {
                      id:1,
                      label:'男',
                      isChecked:false
                    },
                    {
                      id:2,
                      label:'女',
                      isChecked:false
                    }
                ]
             },
             {
                id:2,
                label:'年龄',
                money:'0.50元',
                ageValue:'',
                isChecked:false,
                children:[
                    {
                      id:1,
                      label:'18以下',
                      isChecked:false
                    },
                    {
                      id:2,
                      label:'18-25',
                      isChecked:false
                    },
                    {
                      id:3,
                      label:'26-35',
                      isChecked:false
                    },
                    {
                      id:4,
                      label:'35以上',
                      isChecked:false
                    }
                ]
             },
             {
                id:3,
                label:'地域',
                money:'1.00元',
                isChecked:false,
                children:[
                    {
                      areaid:1,
                      name:'华东',
                      areaValue:'',
                      checkAll: false, //是否全选
                      isCheckedList:[],//选中数据
                      isIndeterminate: true,//是否全选
                      cities:['上海','江苏','浙江','安徽','江西'],
                      areaChildren:[
                          {
                             id:1,
                             label:'上海',
                             isChecked:false
                          },
                          {
                             id:2,
                             label:'江苏',
                             isChecked:false
                          },
                          {
                             id:3,
                             label:'浙江',
                             isChecked:false
                          },
                          {
                             id:4,
                             label:'安徽',
                             isChecked:false
                          },
                          {
                             id:5,
                             label:'江西',
                             isChecked:false
                          }
                      ]
                    },
                    {
                      areaid:2,
                      name:'华北',
                      areaValue:'',
                      checkAll: false, //是否全选
                      isCheckedList:[],//选中数据
                      isIndeterminate: true,//是否全选
                      cities:['北京','天津','山西','山东','河北','内蒙古'],
                      areaChildren:[
                          {
                             id:1,
                             label:'北京',
                             isChecked:false
                          },
                          {
                             id:2,
                             label:'天津',
                             isChecked:false
                          },
                          {
                             id:3,
                             label:'山西',
                             isChecked:false
                          },
                          {
                             id:4,
                             label:'山东',
                             isChecked:false
                          },
                          {
                             id:5,
                             label:'河北',
                             isChecked:false
                          },
                          {
                             id:6,
                             label:'内蒙古',
                             isChecked:false
                          }
                      ]
                    },
                    {
                      areaid:3,
                      name:'华中',
                      areaValue:'',
                      checkAll: false, //是否全选
                      isCheckedList:[],//选中数据
                      isIndeterminate: true,//是否全选
                      cities:['湖南','湖北','河南'],
                      areaChildren:[
                          {
                             id:1,
                             label:'湖南',
                             isChecked:false
                          },
                          {
                             id:2,
                             label:'湖北',
                             isChecked:false
                          },
                          {
                             id:3,
                             label:'河南',
                             isChecked:false
                          },
                      ]
                    },
                    {
                      areaid:4,
                      name:'华南',
                      areaValue:'',
                      checkAll: false, //是否全选
                      isCheckedList:[],//选中数据
                      isIndeterminate: true,//是否全选
                      cities:['广东','广西','福建','海南'],
                      areaChildren:[
                          {
                             id:1,
                             label:'广东',
                             isChecked:false
                          },
                          {
                             id:2,
                             label:'广西',
                             isChecked:false
                          },
                          {
                             id:3,
                             label:'福建',
                             isChecked:false
                          },
                          {
                             id:4,
                             label:'海南',
                             isChecked:false
                          },
                      ]
                    },
                    {
                      areaid:5,
                      name:'东北',
                      areaValue:'',
                      checkAll: false, //是否全选
                      isCheckedList:[],//选中数据
                      isIndeterminate: true,//是否全选
                      cities:['辽宁','吉林','黑龙江'],
                      areaChildren:[
                          {
                             id:1,
                             label:'辽宁',
                             isChecked:false
                          },
                          {
                             id:2,
                             label:'吉林',
                             isChecked:false
                          },
                          {
                             id:3,
                             label:'黑龙江',
                             isChecked:false
                          },
                      ]
                    },
                    {
                      areaid:6,
                      name:'西北',
                      areaValue:'',
                      checkAll: false, //是否全选
                      isCheckedList:[],//选中数据
                      isIndeterminate: true,//是否全选
                      cities:['陕西','新疆','甘肃','宁夏','青海'],
                      areaChildren:[
                          {
                             id:1,
                             label:'陕西',
                             isChecked:false
                          },
                          {
                             id:2,
                             label:'新疆',
                             isChecked:false
                          },
                          {
                             id:3,
                             label:'甘肃',
                             isChecked:false
                          },
                          {
                             id:4,
                             label:'宁夏',
                             isChecked:false
                          },
                          {
                             id:5,
                             label:'青海',
                             isChecked:false
                          }
                      ]
                    },
                    {
                      areaid:7,
                      name:'西南',
                      areaValue:'',
                      checkAll: false, //是否全选
                      isCheckedList:[],//选中数据
                      isIndeterminate: true,//是否全选
                      cities:['重庆','云南','贵州','西藏','四川'],
                      areaChildren:[
                          {
                             id:1,
                             label:'重庆',
                             isChecked:false
                          },
                          {
                             id:2,
                             label:'云南',
                             isChecked:false
                          },
                          {
                             id:3,
                             label:'贵州',
                             isChecked:false
                          },
                          {
                             id:4,
                             label:'西藏',
                             isChecked:false
                          },
                          {
                             id:5,
                             label:'四川',
                             isChecked:false
                          }
                      ]
                    }
                ]
             },
      ],
      //人群标签选中
      isCheckedcrowdLabel:[],

      //付费增值业务信息
       PaymentServiceInfo:{
            reward:'',//加赏任务单
            people:'',//设置执行人数
            jphb:{//竞品货比
                ordernum:'',
                tkl:''
            },
            collectionDesignatedID:1,//收藏指定宝贝默认ID
            searchKeywordsID:1,//指定货比关键词默认ID
            specifyBrowseID:1,//指添加额外副宝贝默认ID
            collectionDesignated:[ //收藏指定宝贝
                  {
                     id:1,
                     name:'',
                     file:'',
                  }
            ],
            searchKeywords:[//指定货比关键词
                 {
                    id:1,
                    text:''
                 }
            ],
            specifyBrowse:[//指添加额外副宝贝
                  {
                     id:1,
                     name:'',
                     file:''
                  }
            ] 
       }
    };
  },
  computed:{
      ...mapState({
           addedServicesList:state=>state.publish.addedServicesList
      })
  },
  methods:{
     //获取付费增值业务
     getPaidValueAddedServices(){
          this.$store.dispatch('getPaidValueAddedServices')
     },

     //付费增值业务发生改变时
     changeAddedServices(id,isChecked){
          this.addedServicesList.forEach(item=>{
                if(item.id == id){
                     item.isChecked = !item.isChecked
                }
          })
     },

     //人群标签选择发生改变时
     changeCrowdLabel(id){
          this.crowdLabel.forEach(item=>{
                if(item.id == id){
                     item.isChecked = !item.isChecked
                }
          })
     },

     //是否全选
     handleCheckAllChange(val,id) {
         this.crowdLabel[2].children.forEach(item=>{
             if(item.areaid == id){
                  // item.isCheckedList = val ? item.cities : []
                  if(val){
                         item.isCheckedList = item.cities
                         item.isIndeterminate = false
                         item.checkAll = true
                         item.areaChildren.forEach(i=>{
                              i.isChecked = true
                         })
                  }else{
                         item.isCheckedList = []
                         item.isIndeterminate = true
                         item.checkAll =false
                         item.areaChildren.forEach(i=>{
                              i.isChecked = false
                         })
                  }
                  // item.isIndeterminate = !item.isIndeterminate
                  // item.checkAll = !item.checkAll
             }
         })
      },

     handleCheckedCitiesChange(areaChildren,areaid) {
         let checkedCount = 0
         this.crowdLabel[2].children.forEach(item=>{
              if(item.areaid == areaid){
                   checkedCount = item.isCheckedList.length
                   item.checkAll = checkedCount === item.cities.length;
                   item.isIndeterminate = checkedCount == 0 || checkedCount < item.cities.length;
              }
         })
     },

     //点击单个城市
     changeCheckedCities(areaid,cityid){
         this.crowdLabel[2].children.forEach(item=>{
               if(item.areaid == areaid){
                    item.areaChildren.forEach(i=>{
                        if(i.id == cityid){
                               i.isChecked = !i.isChecked
                        }
                    })
               }
         })
     },

     //添加额外收藏宝贝
     addExtraCollection(){
            if(this.PaymentServiceInfo.collectionDesignated.length<2){
                  this.PaymentServiceInfo.collectionDesignatedID += 1
                  this.PaymentServiceInfo.collectionDesignated.push({id:this.PaymentServiceInfo.collectionDesignatedID,name:'',file:''})
            }else{
                  this.$message.warning('最多只能添加两个收藏宝贝！')
            }
     },

     //删除额外收藏宝贝
     deleteCollectionDesignated(id){
          this.PaymentServiceInfo.collectionDesignated.splice(this.PaymentServiceInfo.collectionDesignated.findIndex(item => item.id === id), 1)
     },

     //添加指定货比关键词
     addSearchKeywords(){
            if(this.PaymentServiceInfo.searchKeywords.length<3){
                  this.PaymentServiceInfo.searchKeywordsID += 1
                  this.PaymentServiceInfo.searchKeywords.push({id:this.PaymentServiceInfo.searchKeywordsID,text:''})
            }else{
                  this.$message.warning('最多只能添加三个收藏宝贝！')
            }
     },

     //删除指定货比关键词
     deleteSearchKeywords(id){
           this.PaymentServiceInfo.searchKeywords.splice(this.PaymentServiceInfo.searchKeywords.findIndex(item => item.id === id), 1)
     },

     //添加额外副宝贝
     addSpecifyBrowse(){
            if(this.PaymentServiceInfo.specifyBrowse.length<5){
                  this.PaymentServiceInfo.specifyBrowseID += 1
                  this.PaymentServiceInfo.specifyBrowse.push({id:this.PaymentServiceInfo.specifyBrowseID,name:'',file:''})
            }else{
                  this.$message.warning('最多只能添加五个收藏宝贝！')
            }
     },

     //删除额外副宝贝
     deleteSpecifyBrowse(id){
            this.PaymentServiceInfo.specifyBrowse.splice(this.PaymentServiceInfo.specifyBrowse.findIndex(item => item.id === id), 1)
     },

     //文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用  (收藏指定宝贝)
     firstHandleImageChange(file,fileList,id){
            const isJPG = file.raw.type === 'image/jpeg'|| file.raw.type == 'image/png' || file.raw.type == 'image/jpg';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                  this.$message.error('上传图片只能是JPG/PNG/JPEG 格式!');
                  return false
            }else if (!isLt2M) {
                  this.$message.error('上传图片大小不能超过2MB!');
                  return false
            }else if (isLt2M && isJPG) {
               this.PaymentServiceInfo.collectionDesignated.forEach(item=>{
                      if(item.id==id){
                            item.file = file.raw
                      }
               })
          	}
     },

    //删除指定副宝贝图片
     firstHandleRemove(file,fileList,id) {
          this.PaymentServiceInfo.collectionDesignated.forEach(item=>{
                      if(item.id==id){
                            item.file = ''
                      }
         })
     },

     //文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用  (收藏指定副宝贝)
     secondHandleImageChange(file,fileList,id){
            const isJPG = file.raw.type === 'image/jpeg'|| file.raw.type == 'image/png' || file.raw.type == 'image/jpg';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                  this.$message.error('上传图片只能是JPG/PNG/JPEG 格式!');
                  return false
            }else if (!isLt2M) {
                  this.$message.error('上传图片大小不能超过2MB!');
                  return false
            }else if (isLt2M && isJPG) {
               this.PaymentServiceInfo.specifyBrowse.forEach(item=>{
                      if(item.id==id){
                            item.file = file.raw
                      }
               })
          	}
     },

      secondHandleRemove(file,fileList,id) {
        	  this.PaymentServiceInfo.specifyBrowse.forEach(item=>{
                      if(item.id==id){
                            item.file = ''
                      }
            })
      },


     //收集数据
     collectingData(){
           let data = {}
           data.isCheckedAddedServices = this.isCheckedAddedServices //增值业务
           data.reward = this.PaymentServiceInfo.reward //加赏任务单
           data.people = this.PaymentServiceInfo.people //设置执行人数
           data.collectionDesignated = this.PaymentServiceInfo.collectionDesignated //收藏指定宝贝
           data.specifyBrowse = this.PaymentServiceInfo.specifyBrowse //指添加额外副宝贝
           data.searchKeywords = this.PaymentServiceInfo.searchKeywords
           data.jphb = this.PaymentServiceInfo.jphb
           data.isCheckedcrowdLabel = this.isCheckedcrowdLabel  //人群标签选中
           data.crowdLabel = this.crowdLabel
           data.isCheckedOccupation = this.isCheckedOccupation
           data.isCheckedAccountCategory = this.isCheckedAccountCategory
           return data
     }
  },
  created(){
       this.getPaidValueAddedServices()
  },
  mounted(){
       if(this.addedServicesList.length>0){
               this.addedServicesList.forEach(item=>{
                     item.isChecked = false 
          })
       }
  }
};
</script>

<style lang="less" scoped>
.addedServices {
    width: 100%;
    height: auto;
    padding:10px 45px;
    .list{
        margin:10px 5px 20px 5px;
        .item{
            display:flex;
            justify-content:flex-start;
            align-items: center;
            .tip{
                 color: #a2a2a2;
                 font-size: 12px;
                 line-height: 16px;
                 margin-left:10px;
             }
            .money{
                 font-size: 14px;
                 margin-left:10px;
             }

        }
        .wxts_box{
            margin-top:8px;
            img{
                  width: 30px;
                  height: 15px;
            }
            .wxts{
                 color: #a2a2a2;
                 font-size: 12px;
                 line-height: 16px;
                 margin-left:10px;
            }
        }
    }
    .sub_box{
          margin-top:8px;
          margin-left:20px;
          .list{
             .item{
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  .money{
                        font-size: 14px;
                        margin-left: 20px;
                  }
             }
             .sub_list{
                     margin-top:10px;
                     margin-left:40px;
                     .area_box{
                          display: flex;
                          justify-content: flex-start;
                          margin-bottom:10px;
                          .area_main{
                              margin-left:20px;
                          }
                     }
              }

          }
    }
}
.ys{
    width:200px;
}
.tips{
    color: #d40b35;
    font-size: 16px;
    font-weight: 500;
}
</style>