<template>
  <div class="content">
    <el-form status-icon label-width="150px">
      <Title :title="title[0]"></Title>

      <div class="free_box">
        <el-form-item label-width="50px">
          <el-checkbox-group v-model="freeCheckList">
            <div class="free_list" v-for="item in freeList" :key="item.id">
              <el-checkbox :label="item.label" :checked="item.isChecked" @change="changeFreeList(item.id)"></el-checkbox>
              <div class="tip">{{item.tip}}</div>
            </div>
          </el-checkbox-group>
        </el-form-item>
      </div>

       <Title :title="title[1]"></Title>
       <addedaservices ref="addedaservices"/>

       <Title :title="title[2]"></Title>
       <div class="additional_requirements">
                  <el-form-item label="上传图片" label-width="180px">
                        <el-upload
                             action=""
                             list-type="picture-card"
                             :limit="3"
                             :auto-upload="false"
                            multiple
                             :on-remove="(file, fileList) => {thirdHandleRemove(file, fileList)}"
                             :on-change="(file, fileList) => {thirdHandleImageChange(file, fileList)}"
                         >
                        <i class="el-icon-plus"></i>
                        </el-upload>
                      <p class="tips">（最多仅支持上传三张图片，图片中不要显示店铺名或商品名，避免用户直接搜索。单张图片大小不能超过2MB，图片格式可以为JPG、PNG、JPEG）</p>
                  </el-form-item>

                  <el-form-item label="商家备注内容" label-width="180px">
                     <el-input type="textarea" :rows="3" placeholder="请输入文字好评内容" v-model="remarkInfo" style="width:800px"></el-input>
                  </el-form-item>
       </div>

       
       <div class="operation_btns">
            <el-button type="primary" class="next_step" :loading="loading" @click="nextStep">下一步</el-button>
       </div>
    </el-form>
  </div>
</template>

<script>
import addedaservices from './addedServices/addedservices'
import { isNumber, isNumTwo } from "@/utils/validate";
import { mapState } from 'vuex'
import throttle from "lodash/throttle";
export default {
  data() {
    return {
      freeList:[
          {
            id:1,
            label:'无线端下单',
            tip:'（用户会通过手机淘宝的方式领取任务，完成浏览/加购/收藏/下单/评价等方式进一步提升你的单品转化权重和店铺转化权重！）',
            isChecked:true
          },
          {
            id:2,
            label:'账号安全',
            tip:'（中奖用户淘宝订单量不超过5，月订单量不超过10，身份证实名验证）',
            isChecked:true
          },
          {
            id:3,
            label:'账号防重复',
            tip:'（同个商品在规定时间内不会有人重复中奖）',
            isChecked:true
          },
          {
            id:4,
            label:'优先审核',
            tip:'（你发布的活动系统会优先安排审核，审核通过之后获得最佳的发布曝光时间！）',
            isChecked:true
          }
      ],
      title: ["选择免费业务", "选择付费增值业务", "商家附加要求"],
      freeCheckList:[],
      //附加图片信息
      additionalPicturesinfo:[],
      remarkInfo:'',  //商家备注信息
      //提交表单状态
      loading:false,
    };
  },
  computed:{
      ...mapState({
          firstBhCode:state => state.publish.firstBhCode
      })
  },
  components:{
      addedaservices
  },
  methods:{
     //切换免费业务
     changeFreeList(id){
           this.freeList.forEach(item=>{
                if(item.id==id){
                      item.isChecked = !item.isChecked
                }
           })
     },

    //删除图片
    thirdHandleRemove(file,fileList){
        const uid = file.uid
        this.additionalPicturesinfo.splice(this.additionalPicturesinfo.findIndex(item => item.uid === uid), 1)
    },


    //文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用  (收藏指定副宝贝)
     thirdHandleImageChange(file,fileList){
            const isJPG = file.raw.type === 'image/jpeg'|| file.raw.type == 'image/png' || file.raw.type == 'image/jpg';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                  this.$message.error('上传图片只能是JPG/PNG/JPEG 格式!');
                  return false
            }else if (!isLt2M) {
                  this.$message.error('上传图片大小不能超过2MB!');
                  return false
            }else if (isLt2M && isJPG) {
                this.additionalPicturesinfo.push(file)
          	}
     },

    //下一步
    nextStep:throttle(async function(){
        try{
              this.loading = true
              let data = this.$refs.addedaservices.collectingData()
              const formData = new FormData()
              // let bh ='T11648102956924581'
              formData.append('bh',this.firstBhCode);
              formData.append('freebusuness',this.freeCheckList);
              formData.append('paybusiness',JSON.stringify(data));
              data.collectionDesignated.forEach(item =>{
                   formData.append('firstFileList[]', item.file)
              })
     
     
              data.specifyBrowse.forEach(item =>{
                   formData.append('secondFileList[]', item.file)
              })
     
     
              this.additionalPicturesinfo.forEach(file => {  //备注图片
                  		formData.append('fileList[]', file.raw)
              })
              formData.append('remarks',this.remarkInfo); //备注
              await this.$store.dispatch('publishTaskSecondModuleSubmit',formData)
              this.loading = false
              this.$emit("changeStepsActive",3)
        }catch(error){      
              this.$message.warning(error.message)
              this.loading = false
        } 
     
    },1000)
  }
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  .free_box {
    margin-top: 20px;
    .free_list{
        .tip{
            color: #a2a2a2;
            font-size: 12px;
            line-height: 16px;
        }
    }
  }
  .additional_requirements{

  }
  .operation_btns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:50px;
    .next_step {
      width: 300px;
    }
  }
}
</style>