<template>
  <div class="publishOrderSettings">
    <el-form status-icon label-width="150px" :model="planAllData" ref="planForm">
       <el-form-item label="总订单数">
         <el-input :disabled="true" class="ys_t" v-model="allNum">
               <span slot="append">单</span>
         </el-input>
       </el-form-item>
   
       <el-form-item label="设置买手付款时间（过零点即算1天)" label-width="300px">
         <el-radio v-model="planAllData.paymentTimeRadio" label="1">当天</el-radio>
       </el-form-item>
   
       <el-form-item label="中奖超过" prop="hour" :rules="{ required: true, validator:orderNum, trigger: 'blur' }">
         <el-input class="ys_t" v-model.number="planAllData.hour">
              <span slot="append">小时</span>
         </el-input>
         <span class="s_span">后自动撤销</span>
         <p class="tip">温馨提示：系统默认24小时。商家可以自己设置大于2小时小于24小时的时间自动撤销</p>
       </el-form-item>
   
       <div class="main_box">
         <div class="tit">
               <span class="t_one">计划放单总数必须等于发布任务总量，</span>
               <span class="t_two">间隔分钟等于0，默认开始时间一到一起发布，如：每隔10分钟放出10单，即时间一到，10会同时发出。</span>
         </div>

         <div class="contant" v-for="(item,index) in planAllData.planValue" :key="item.id">
           <el-form-item label="订单发布开始时间" label-width="140px">
              <el-date-picker v-model="item.timeValue" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
           </el-form-item>
   
           <el-form-item label="每隔" label-width="80px" :prop="`planValue[${index}].minute`" :rules="{ required: true, validator:orderNum, trigger: 'blur' }">
             <el-input class="ys_t" v-model.number="item.minute">
                  <span slot="append">分钟</span>
             </el-input>
           </el-form-item>
   
           <el-form-item label="放出" label-width="80px" :prop="`planValue[${index}].num`" :rules="{ required: true, validator:orderNum, trigger: 'blur' }">
             <el-input class="ys_t" v-model.number="item.num">
                 <!-- <span slot="append">单</span> -->
             </el-input>
           </el-form-item>

           <el-form-item label="共放出" label-width="80px" :prop="`planValue[${index}].allnum`" :rules="{ required: true, validator:orderNum, trigger: 'blur' }">
             <el-input class="ys_t" v-model.number="item.allnum">
                 <span slot="append">单</span>
             </el-input>
           </el-form-item>
   
           <el-button type="danger" icon="el-icon-delete" class="delete" circle @click="deletePlan(item.id)" v-if="item.id!==1"></el-button>
         </div>
        <el-button type="primary" class="haowu hw-add" @click="addPlan">&nbsp;&nbsp;添加发单时间计划</el-button>
       </div>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      planAllData:{
          paymentTimeRadio: "1",
          planID:1,
          hour:'',
          planValue:[
              {
                  id:1,
                  timeValue:'',
                  minute:1,
                  num:1,
                  allnum:''
              }
          ]
      }
    
    };
  },
  props:['allNum'],
  methods:{
      //添加发单计划
      addPlan(){
          if(this.planAllData.planValue.length<10){
              this.planAllData.planID += 1         
              this.planAllData.planValue.push({id:this.planAllData.planID,timeValue:'',minute:1,num:1,allnum:''})
          }else{
              this.$message.warning('最多添加十单，不能添加更多啦~')
          }
          
      },

      //删除发单计划
      deletePlan(id){
          this.planAllData.planValue.splice(this.planAllData.planValue.findIndex(item => item.id === id), 1)
      },

     //单数
     orderNum(rule,value,cb){
          if (!value) {
             return cb(new Error('输入不能为空'));
         }else{
             setTimeout(() => {
               if (!Number.isInteger(value)) {
                  cb(new Error('请输入数字值'));
               } else {
                 if (value<=0) {
                   cb(new Error('请输入正整数'))
                 } else {
                   cb();
                 }
               }
             }, 1000);
         }
     },
     
     validate(callback){
        this.$refs.planForm.validate((valid) => {
          callback(valid);
        });
    },

    //收集第四小模块数据  
    collectionFourth() {
      let fourthdata = {};
      fourthdata.allNum = this.allNum;//总单数
      fourthdata.hour = this.planAllData.hour;
      fourthdata.planValue = this.planAllData.planValue;
      return fourthdata;
    }
     
  }
};
</script>

<style lang="less" scoped>
.publishOrderSettings {
  height: auto;
  margin-top: 15px;
  margin-bottom: 30px;
  padding: 15px 30px;
  background: #f4f5f6;
  border: 1px solid #e4e7ed;
  .main_box {
    margin: 0 50px 0;
    .tit {
      .t_one {
        color: #d40b35;
        font-size: 14px;
      }
      .t_two {
        color: #a2a2a2;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .contant {
        margin-top:20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
  }
}
.ys {
  width: 300px;
}
.ys_t{
  width: 180px;
}
.s_span {
  margin-left: 10px;
}
.tip {
  color: #a2a2a2;
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
}
.delete{
    margin-left:20px;
}
</style>