<template>
  <div>
    <el-steps :active="stepsActive" finish-status="success" simple>
        <el-step title="步骤一"></el-step>
        <el-step title="步骤二"></el-step>
        <el-step title="步骤三"></el-step>
    </el-steps>
  </div>
</template>

<script>
export default {
    data(){
         return{
            
         }    
    },
     props:['stepsActive']
};
</script>

<style lang="less" scoped>
.el-steps {
  margin-bottom: 20px;
}
</style>