<template>
  <div class="searchKeywords">
    <el-form status-icon label-width="150px"  :model="firstAllData" ref="firstForm">
      <p class="smalltip">移动端搜索关键词:填写宝贝标题里有的词作为关键词，如未按要求填写，会导致搜索不到，影响活动效果，降低综合排名。搜索关键词尽可能在前5页可以找到，找不到的请设置卡价格和地址，避免用户找不到造成大量放弃。</p>
      <div class="main_box">
        <el-form-item label="任务类型">
          <el-radio-group v-model="firstAllData.taskTypeRadio" @change="changeTaskTypeRadio">
            <el-radio :label="1">普通任务</el-radio>
            <el-radio :label="2">指定文字好评任务（每单1.00元）</el-radio>
            <el-radio :label="3">指定图片好评任务（每单1.00元）</el-radio>
            <el-radio :label="4">指定图文好评任务（每单2.00元）</el-radio>
          </el-radio-group>
        </el-form-item>

        <div class="content" v-if="firstAllData.taskTypeRadio==1">
          <div class="nr_tbox" v-for="(item,index) in firstAllData.firstValue" :key="item.id">
            <div class="index">{{index+1}}</div>
            <div class="t_main">
              <el-form-item label="搜索关键词" label-width="110px" :prop="`firstValue[${index}].searchValue`" :rules="{ required: true, validator:allSpaces, trigger: 'blur' }">
                <el-input v-model="item.searchValue"></el-input>
              </el-form-item>

              <el-form-item label="排序方式" label-width="90px">
                <el-select v-model="item.sortValue" placeholder="请选择" style="width:100px">
                  <el-option
                    v-for="item in firstAllData.sortOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="设置单数" label-width="90px" :prop="`firstValue[${index}].num`" :rules="{ required: true, validator:orderNum, trigger: 'blur' }">
                <el-input v-model.number="item.num" style="width:150px"  @input="(val)=>changeAllOrder(val,item.id)"></el-input>
              </el-form-item>

              <el-button type="danger" class="delete" v-if="index!==0" @click="deleteSearchKeywords(item.id)">删除</el-button>
            </div>
          </div>
          <div class="nr_bbox">
            <el-button type="primary" class="haowu hw-add" @click="addSearchKeywords">&nbsp;&nbsp;增加搜索关键词</el-button>
            <p>普通任务默认5星好评。</p>
          </div>
        </div>

        <div class="content" v-else-if="firstAllData.taskTypeRadio==2">
          <div class="nr_tbox" v-for="(item,index) in firstAllData.firstValue" :key="item.id">
            <div class="index">{{index+1}}</div>
            <div class="t_main">
              <el-form-item label="搜索关键词" label-width="100px" :prop="`firstValue[${index}].searchValue`" :rules="{ required: true, validator:allSpaces, trigger: 'blur' }">
                <el-input v-model="item.searchValue"></el-input>
              </el-form-item>

              <el-form-item label="排序方式" label-width="90px">
                <el-select v-model="item.sortValue" placeholder="请选择" style="width:100px">
                  <el-option
                    v-for="item in firstAllData.sortOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="设置单数" label-width="100px" :prop="`firstValue[${index}].num`" :rules="{ required: true, validator:orderNum, trigger: 'blur' }">
                <el-input v-model.number="item.num" style="width:100px" @input="(val)=>changeAllOrder(val,item.id)"></el-input>
              </el-form-item>

              <el-button
                type="danger"
                class="delete"
                v-if="index!==0"
                @click="deleteSearchKeywords(item.id)"
              >删除</el-button>
            </div>
            <div class="t_submain">
              <el-form-item label="设置指定文字好评内容" label-width="200px">
                <div class="submain_index">
                  <span>设置第</span>
                  <div class="s_index">{{index+1}}</div>
                  <span>单的文字</span>
                </div>
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="item.DYD_text"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="nr_bbox">
            <el-button
              type="primary"
              class="haowu hw-add"
              @click="addSearchKeywords"
            >&nbsp;&nbsp;增加搜索关键词</el-button>
          </div>
        </div>

        <div class="content" v-else-if="firstAllData.taskTypeRadio==3">
          <div class="nr_tbox" v-for="(item,index) in firstAllData.firstValue" :key="item.id">
            <div class="index">{{index+1}}</div>
            <div class="t_main">
              <el-form-item label="搜索关键词" label-width="100px" :prop="`firstValue[${index}].searchValue`" :rules="{ required: true, validator:allSpaces, trigger: 'blur' }">
                <el-input v-model="item.searchValue"></el-input>
              </el-form-item>

              <el-form-item label="排序方式" label-width="90px">
                <el-select v-model="item.sortValue" placeholder="请选择" style="width:100px">
                  <el-option
                    v-for="item in firstAllData.sortOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="设置单数" label-width="100px" :prop="`firstValue[${index}].num`" :rules="{ required: true, validator:orderNum, trigger: 'blur' }">
                <el-input v-model.number="item.num" style="width:100px" @input="(val)=>changeAllOrder(val,item.id)"></el-input>
              </el-form-item>

              <el-button
                type="danger"
                class="delete"
                v-if="index!==0"
                @click="deleteSearchKeywords(item.id)"
              >删除</el-button>
            </div>
            <div class="t_submain">
              <el-form-item label="设置指定图片好评" label-width="200px">
                <div class="submain_index">
                  <span>设置第</span>
                  <div class="s_index">{{index+1}}</div>
                  <span>单的图片</span>
                </div>
                <el-upload
                  action
                  list-type="picture-card"
                  :limit="5"
                  :auto-upload="false"
                  multiple
                  :on-remove="(file, fileList) => {firstHandleRemove(file,fileList,item.id)}"
                  :on-change="(file, fileList) => {firstHandleImageChange(file,fileList,item.id)}"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <p
                  class="tips"
                >（最多仅支持上传五张图片，图片中不要显示店铺名或商品名，避免用户直接搜索。单张图片大小不能超过2MB，图片格式可以为JPG、PNG、JPEG）</p>
              </el-form-item>
            </div>
          </div>
          <div class="nr_bbox">
            <el-button
              type="primary"
              class="haowu hw-add"
              @click="addSearchKeywords"
            >&nbsp;&nbsp;增加搜索关键词</el-button>
          </div>
        </div>

        <div class="content" v-else>
          <div class="nr_tbox" v-for="(item,index) in firstAllData.firstValue" :key="item.id">
            <div class="index">{{index+1}}</div>
            <div class="t_main">
              <el-form-item label="搜索关键词" label-width="100px" :prop="`firstValue[${index}].searchValue`" :rules="{ required: true, validator:allSpaces, trigger: 'blur' }">
                <el-input v-model="item.searchValue"></el-input>
              </el-form-item>

              <el-form-item label="排序方式" label-width="90px">
                <el-select v-model="item.sortValue" placeholder="请选择" style="width:100px">
                  <el-option
                    v-for="item in firstAllData.sortOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="设置单数" label-width="100px" :prop="`firstValue[${index}].num`" :rules="{ required: true, validator:orderNum, trigger: 'blur' }">
                <el-input v-model.number="item.num" style="width:100px" @input="(val)=>changeAllOrder(val,item.id)"></el-input>
              </el-form-item>

              <el-button
                type="danger"
                class="delete"
                v-if="index!==0"
                @click="deleteSearchKeywords(item.id)"
              >删除</el-button>
            </div>
            <div class="t_submain">
              <el-form-item label="设置指定文字好评内容" label-width="200px">
                <div class="submain_index">
                  <span>设置第</span>
                  <div class="s_index">{{index+1}}</div>
                  <span>单的文字</span>
                </div>
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="item.DYD_text"></el-input>
              </el-form-item>

              <el-form-item label="设置指定图片好评" label-width="200px">
                <div class="submain_index">
                  <span>设置第</span>
                  <div class="s_index">{{index+1}}</div>
                  <span>单的图片</span>
                </div>
                <el-upload
                  action
                  list-type="picture-card"
                  :limit="5"
                  :auto-upload="false"
                  multiple
                  :on-remove="(file, fileList) => {firstHandleRemove(file,fileList,item.id)}"
                  :on-change="(file, fileList) => {firstHandleImageChange(file,fileList,item.id)}"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <p
                  class="tips"
                >（最多仅支持上传五张图片，图片中不要显示店铺名或商品名，避免用户直接搜索。单张图片大小不能超过2MB，图片格式可以为JPG、PNG、JPEG）</p>
              </el-form-item>
            </div>
          </div>
          <div class="nr_bbox">
            <el-button
              type="primary"
              class="haowu hw-add"
              @click="addSearchKeywords"
            >&nbsp;&nbsp;增加搜索关键词</el-button>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
// import { allSpaces, isNumber, isNumTwo } from "@/utils/validate";
export default {
  data() {
    return {
      //第一模块总数据
      firstAllData: {
        //任务类型默认值
        taskTypeRadio: 1,
        sortOptions: [
          {
            value: "1",
            label: "综合"
          },
          {
            value: "2",
            label: "销量"
          },
          {
            value: "3",
            label: "价格"
          },
          {
            value: "4",
            label: "信用"
          }
        ],
        firstItemID: 1,
        firstValue: [
          {
            id: 1,
            searchValue: "", ///搜索关键词
            sortValue: "综合", //排序方式
            num:1, //设置单数
            DYD_text: "", //设置第n单的文字
            imageList: [] //图片集合
          }
        ]
      },

      // 第一模块验证规则
      // firsetItemRules: {
      //   searchValue: [
      //     { required: true, message: "搜索关键词不能为空", trigger: "blur" },
      //     { validator: allSpaces, trigger: "blur" }
      //   ],
      //   num: [
      //     { required: true, message: "设置单数不能为空", trigger: "blur" },
      //     { validator: isNumber, trigger: "blur" }
      //   ]
      // }
    };
  },
  methods: {
    //切换任务类型
    changeTaskTypeRadio(val) {
      this.firstAllData.taskTypeRadio = val,
      this.firstAllData.firstItemID = 1,
      this.firstAllData.firstValue = [
        {
          id: 1,
          searchValue: "", ///搜索关键词
          sortValue: "综合", //排序方式
          num: 1, //设置单数
          DYD_text: "", //设置第n单的文字
          imageList: [] //图片集合
        }
      ];
      this.$emit('changeAllOrder',1)
    },
    //添加搜索关键词
    addSearchKeywords() {
      let allNum = 0 
      this.firstAllData.firstValue.forEach(item=>{
              allNum += item.num
      })

      if(allNum<10000){
                this.firstAllData.firstItemID += 1;
               if (this.firstAllData.taskTypeRadio == 1) {
                 if (this.firstAllData.firstValue.length < 10) {
                   this.firstAllData.firstValue.push({
                     id: this.firstAllData.firstItemID,
                     searchValue: "",
                     sortValue: "综合",
                     num:1,
                     DYD_text: "",
                     imageList: []
                   });
                 } else {
                   this.$message.warning("最多设置10个");
                 }
               } else if (this.firstAllData.taskTypeRadio == 2) {
                 if (this.firstAllData.firstValue.length < 10) {
                   this.firstAllData.firstValue.push({
                     id: this.firstAllData.firstItemID,
                     searchValue: "",
                     sortValue: "综合",
                     num: 1,
                     DYD_text: "",
                     imageList: []
                   });
                 } else {
                   this.$message.warning("最多设置10个");
                 }
               } else if (this.firstAllData.taskTypeRadio == 3) {
                 if (this.firstAllData.firstValue.length < 10) {
                   this.firstAllData.firstValue.push({
                     id: this.firstAllData.firstItemID,
                     searchValue: "",
                     sortValue: "综合",
                     num: 1,
                     DYD_text: "",
                     imageList: []
                   });
                 } else {
                   this.$message.warning("最多设置10个");
                 }
               } else {
                 if (this.firstAllData.firstValue.length < 10) {
                   this.firstAllData.firstValue.push({
                     id: this.firstAllData.firstItemID,
                     searchValue: "",
                     sortValue: "综合",
                     num: 1,
                     DYD_text: "",
                     imageList: []
                   });
                 } else {
                   this.$message.warning("最多设置10个");
                 }
             }
      }else{
              this.$message.warning("总订单数不能超过10000单！");
      }
     
    },

    //删除搜索关键词
    deleteSearchKeywords(id) {
      this.firstAllData.firstValue.splice(
        this.firstAllData.firstValue.findIndex(item => item.id === id),1);
      //   this.firstItemID -= 1
    },

    //文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    firstHandleImageChange(file, fileList, id) {
      const isJPG =
        file.raw.type === "image/jpeg" ||
        file.raw.type == "image/png" ||
        file.raw.type == "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传图片只能是JPG/PNG/JPEG 格式!");
        return false;
      } else if (!isLt2M) {
        this.$message.error("上传图片大小不能超过2MB!");
        return false;
      } else if (isLt2M && isJPG) {
        this.firstAllData.firstValue.forEach(item => {
          if (item.id == id) {
            item.imageList.push(file.raw);
          }
        });
      }
    },

    //删除图片
    firstHandleRemove(file, fileList, id) {
      const uid = file.uid;
      this.firstAllData.firstValue.forEach(item => {
        if (item.id == id) {
          item.imageList.splice(
            item.imageList.findIndex(i => i.uid == uid),
            1
          );
        }
      });
    },

    //搜索关键词
    allSpaces(rule, value, cb){
        //验证输入全部为空格的正则
        const regAllSpaces = /^[ ]+$/;
        if(value){
              if (regAllSpaces.test(value)) {
                    return cb(new Error('搜索关键词不能全为空'));
              }else{
                    cb();
              }
        }else{
               return cb(new Error('搜索关键词不能为空'));
        }
        
    },

    //单数
    orderNum(rule,value,cb){
          if (!value) {
             return cb(new Error('输入不能为空'));
         }else{
             setTimeout(() => {
               if (!Number.isInteger(value)) {
                  cb(new Error('请输入数字值'));
               } else {
                 if (value<=0) {
                   cb(new Error('请输入正整数'))
                 } else {
                   cb();
                 }
               }
             }, 1000);
         }
   },

    //改变总订单数
    changeAllOrder(val,id){
        let allNum = 0
        let otherNum = 0
        this.firstAllData.firstValue.forEach(item=>{
              allNum  += item.num
        })
        if(allNum<=10000){
             this.$emit('changeAllOrder',allNum)
        }else{
             this.$message.warning('总订单数不能超过10000单！')
             this.firstAllData.firstValue.forEach(item=>{               
                    if(item.id!==id){
                          otherNum += item.num
                    }
             })
             this.firstAllData.firstValue.forEach(item=>{               
                    if(item.id==id){
                          item.num = 10000 - otherNum 
                    }
             })
            this.$emit('changeAllOrder',10000)
        }
        
    },

    validate(callback){
      this.$refs.firstForm.validate((valid) => {
        callback(valid);
      });
    },

    //收集第一小模块数据
    collectionFirst() {
      let firstdata = {};
      firstdata.taskTypeRadio = this.firstAllData.taskTypeRadio;
      firstdata.firstValue = this.firstAllData.firstValue;
      return firstdata;
    }
  },
  watch: {
            'firstAllData.firstValue.length': {
                handler(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        // 操作
                        this.changeAllOrder()
                    }
                }
            }
  }
  
};
</script>

<style lang="less" scoped>
// @assets:"~@/assets";
// @import "@{assets}/css/theme.less";
.searchKeywords {
  width: 100%;
  height: auto;
  .main_box {
    border: 1px solid rgba(@themeColor,1);
    margin-top: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    .content {
      margin: 0 80px 0;
      .nr_tbox {
        height: auto;
        border: 1px solid rgba(@themeColor,1);
        padding: 25px 15px 5px;
        position: relative;
        margin-bottom: 15px;
        .index {
          background:rgba(@themeColor,1);
          color:#ffffff;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          line-height: 26px;
          text-align: center;
          margin-right: 10px;
          position: absolute;
          left: -10px;
          top: -10px;
        }
        .t_main {
          display: flex;
          justify-content: start;
          align-items: flex-start;
          .delete {
            margin-left: 20px;
          }
        }
        .t_submain {
          width: 100%;
          height: auto;
          .submain_index {
            display: flex;
            justify-content: start;
            align-items: center;
            font-size: 18px;
            font-weight: 600;
            .s_index {
              background:rgba(@themeColor,1);
              color:#ffffff;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              line-height: 26px;
              text-align: center;
              margin: 0 5px 0;
            }
          }
        }
      }
      .nr_bbox {
        width: 100%;
        height: auto;
        padding: 10px 0 10px 0;
        margin-top: 10px;
        p {
          color: #a2a2a2;
          font-size: 12px;
          line-height: 16px;
          margin-top: 10px;
        }
      }
    }
  }
}
.smalltip {
  margin-left: 20px;
  color: #d40b35;
  font-size: 14px;
  font-weight: 500;
}
</style>